<template>
  <div class="lecture_warp">
    <TopCard ref="TopCardRef"
             text="专题讲解"
             :showBack="true" />
    <div class="category">
      <div class="label">分类：</div>
      <div class="categorys">
        <div v-for="item in cateList"
             :class="{'cative':item.sourceCode == sourceCode}"
             @click="check(item)"
             :key="item.sourceCode">
          {{item.sourceName}}
        </div>
      </div>
    </div>
    <div class="subject_search">
      <div class="subject">
        <div v-for="item in subjectList"
             :class="{'active_subject':subjectId == item.videoCode}"
             @click="checkSubject(item)"
             :key="item.videoCode">
          {{item.videoType}}
        </div>
      </div>
      <div class="search">
        <el-input placeholder="请输入视频名称"
                  v-model="keyword">
          <i class="el-icon-search"
             slot="suffix"
             @click="getData">
          </i>
        </el-input>
      </div>
    </div>
    <div class="list">
      <div class="video"
           v-for="item in filtList"
           :key="item.url">
        <div class="pic">
          <img :src="item.pic"
               alt=""
               v-if="item.pic">
          <img v-else
               src="@/assets/course/视频默认.png"
               alt="">
        </div>
        <div class="name">
          {{item.title}}
        </div>
        <div class="btn_wrap">
          <div class="btn"
               @click="showViedo(item)">
            开始播放 <img src="@/assets/lecture/start.png"
                 alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <pagination ref="pagination"
                  :get-data="getData"
                  :now-page.sync="page"
                  :now-size.sync="size"
                  :total="total" />
    </div>
  </div>
</template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import { getCategory, getSubject, getList } from '@/api/lecture.js'
export default {
  data () {
    return {
      tableData: [],
      cateList: [],
      sourceCode: '',
      subjectList: [],
      subjectId: '',
      keyword: '',
      total: 0,
      page: 1,
      size: 20,
      list: []
    }
  },
  components: {
    TopCard
  },
  computed: {
    filtList () {
      var list = JSON.parse(JSON.stringify(this.list))
      if (this.keyword !== '') {
        list = this.list.filter(item => {
          if (item.title.indexOf(this.keyword) != -1) {
            return item
          }
        })
      }
      this.total = list.length
      return list.splice((this.page - 1) * this.size, this.page * this.size)
    }
  },
  mounted () {
    this.initList()
  },
  methods: {
    async initList () {

      const { data } = await getCategory()
      this.cateList = data
      this.check(this.cateList[0])
    },
    check (row) {
      this.sourceCode = row.sourceCode
      this.getSubject()
    },
    async getSubject () {
      let params = {
        sourceCode: this.sourceCode,
        target_type: 1
      }
      const { data } = await getSubject(params)
      this.subjectList = data
      if (this.subjectList.length > 0) {
        this.subjectId = this.subjectList[0].videoCode
      }
      else {
        this.subjectId = 0
      }
      this.getData()
    },
    async getData (page, limit) {
      if (page || limit) {
        this.page = page
        this.size = limit
      }
      // if (this.sourceCode == '' || this.subjectId == '') return
      if (this.sourceCode == '') return

      let params = {
        sourceCode: this.sourceCode,
        videoCode: this.subjectId,
        target_type: 1,
        keyword: this.keyword
      }
      const { data } = await getList(params)
      this.list = data
    },
    checkSubject (item) {
      this.subjectId = item.videoCode
      this.getData()
    },
    showViedo (item) {
      // window.open(item.url, '_blank')
      let routeUrl = this.$router.resolve({
        path: "/video?video",
        query: { video: item.url }
      });
      window.open(routeUrl.href, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
.lecture_warp {
  background: #f6f6f6;
  .category {
    width: 100%;
    height: 80rem;
    display: flex;
    .label {
      width: 100rem;
      text-align: right;
      font-size: 20rem;
      font-weight: bold;
      color: #333333;
      height: 40rem;
      line-height: 40rem;
    }
    .categorys {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      font-size: 20rem;
      font-weight: bold;
      justify-content: flex-start;
      div {
        margin-right: 40rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999999;
        cursor: pointer;
      }
      .cative {
        background: #2196f3 !important;
        border-radius: 2rem !important;
        color: #ececec !important;
        padding: 0 20rem !important;
      }
    }
  }
  .subject_search {
    display: flex;
    height: 44rem;
    margin-bottom: 10rem;
    margin-top: 10rem;
    position: relative;
    .subject {
      width: calc(100% - 40%);
      display: flex;
      justify-content: flex-end;
      div {
        font-size: 20rem;
        font-weight: bold;
        color: #333333;
        padding: 0 20rem;
        cursor: pointer;
      }
    }
    .search {
      position: absolute;
      width: 31%;
      height: 44rem;
      right: 50rem;
      ::v-deep .el-input--medium .el-input__inner {
        height: 44rem;
        line-height: 44rem;
        border-radius: 27rem;
        font-size: 18rem;
        font-weight: 500;
        color: #999999;
        background: white;
      }
      ::v-deep .el-icon-search {
        margin-right: 20rem;
        font-size: 22rem;
      }
      ::v-deep .el-input__suffix {
        display: flex;
        align-items: center;
      }
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10rem;
    height: calc(100vh - 303rem);
    overflow-y: auto;
    .video {
      width: 320rem;
      height: 300rem;
      background: white;
      box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
      border-radius: 20rem;
      margin-left: 50rem;
      margin-top: 30rem;
      .pic {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-top: 20rem;
          margin-bottom: 22rem;
          width: 200rem;
          height: 112rem;
        }
      }
      .name {
        font-size: 20rem;
        font-weight: bold;
        color: #333333;
        margin: 0 30rem;
        height: 56rem;
      }
      .btn_wrap {
        display: flex;
        justify-content: center;
        margin-top: 22rem;
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 180rem;
          height: 44rem;
          background: #2196f3;
          border-radius: 40rem;
          font-size: 22rem;
          color: #ffffff;
          cursor: pointer;
          img {
            width: 24rem;
            height: 24rem;
            margin-left: 12rem;
          }
        }
      }
    }
  }
  .active_subject {
    border-bottom: 4rem solid #1890ff;
    color: #2196f3 !important;
  }
}
</style>